import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import loadable from "@loadable/component"

// Load DataEngineering component using loadable
const DataEngineering = loadable(
  () => import("../components/DataEngineering"),
  {
    fallback: <div className="loading-fallback">Loading...</div>, // Loading indicator while component is being loaded
  }
)

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://codefulcrum.com/data-engineering/"
        />
      </Helmet>
      <DataEngineering />
    </Layout>
  )
}
